import axios from "axios"

function initialize() {
    let app = document.querySelector('.check-a-chimp')!

    const template = require("./check-a-chimp.handlebars")

    function bindHandlers() {
        app.querySelector("form")?.addEventListener("submit", check)
        app.querySelector("form")?.addEventListener("submit", check)
        app.querySelector("[name='generative']")?.addEventListener("click", () => select("generative"))
        app.querySelector("[name='genesis']")?.addEventListener("click", () => select("genesis"))
        app.querySelector("[name='reset']")?.addEventListener("click", load)
    }

    async function check(e: Event) {
        e.preventDefault()
        const form = (e.target as HTMLFormElement)
        const type = form.elements.namedItem("type") as HTMLInputElement
        const id = form.elements.namedItem("id") as HTMLInputElement

        const response = await axios(`https://api.chimpers.xyz/adventure/check-a-chimp/${parseInt(id.value) + (type.value == "generative" ? 10000 : 0)}`)
        if (response.status != 200) {
            return
        }

        const result = await response.data

        const inventory = new Array()
        for (let i = 0; i < result.bandanas?.length; i++) {
            ``
            const bandana = result.bandanas[i]
            inventory.push(bandana)
        }
        if (result.jewels?.length > 0) {
            inventory.push("amulet")
        }
        for (let i = 0; i < result.jewels?.length; i++) {
            const jewel = result.jewels[i]
            inventory.push(jewel)
        }
        const remaining = (inventory.length > 12 ? 27 : 12) - inventory.length
        for (let i = 0; i < remaining; i++) {
            inventory.push("")
        }

        app.innerHTML = template({
            started: true,
            type: type.value,
            id: id.value,
            result,
            inventory
        })
        bindHandlers()
    }

    function select(type: string) {
        app.innerHTML = template({
            started: true,
            type
        })
        bindHandlers()
    }

    function load() {
        if (app) {
            app.innerHTML = template({})
            app.querySelector("button")?.addEventListener("click", () => {
                app.innerHTML = template({ started: true })
                bindHandlers()
            })
        }
    }

    load()
}

if (document.readyState == "complete" || document.readyState == "interactive") {
    initialize()
} else {
    window.addEventListener("DOMContentLoaded", initialize)
}